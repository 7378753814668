@import url(https://fonts.googleapis.com/css?family=Signika);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Signika", sans-serif;
  font-size: 14px !important;
  line-height: 1.2 !important;
  color: #fff;
  background-image: url("https://incighttv.com/files/images/incighttv-background.jpg");
  background-color: #000000 !important;
  background-repeat: no-repeat;
  background-position: top center;
  font-weight: 700;
}

.container {
  width: 960px !important;
  max-width: 960px !important;
}

h3.title {
  text-transform: capitalize;
}

h4.title {
  font-size: 18px;
  margin: 1rem 0;
  line-height: 1.2em;
  margin-bottom: 12px !important;
}

h2,
h3,
h4,
p {
  color: #ffffff;
  font-weight: 100;
}

a,
a:link,
a:visited,
a:hover {
  color: #e42a25;
}

.hdrTitle {
  padding: 40px 0 0 30px;
  text-transform: capitalize;
}

h3.hdrTitle,
h2.hdrTitle {
  font-weight: 400;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border: 0;
}

a.nav-link {
  color: #ffffff;
}

.thumbnail {
  display: inline-block;
  width: 49%;
}

.fader:after {
  width: 100%;
  height: 70%;
  bottom: 0%;
  left: 0;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0) 0%, #141414 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
  content: "";
  z-index: 0;
  position: absolute;
}

.dimmer:after {
  width: 100%;
  height: 50%;
  bottom: -1px;
  left: 0;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0) 0%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
  content: "";
  z-index: 0;
  position: absolute;
}

.bg {
  width: 100%;
  padding-top: 57%;
  background-size: cover;
  position: relative;
  background-position: center center;
  transition: all 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.bg a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar-default {
  background-color: transparent;
  border: 0;
  max-width: 900px;
  margin: 0 auto;
}

.navbar-brand {
  height: 80px;
}

.navbar-default .navbar-nav > li > a {
  color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #e42a25;
}

.navbar-nav {
  padding-top: 10px;
}

/* make channel rows horizontal */

.channelrow {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100px;
  border-color: transparent;
}

.channelthumbnail {
  width: 150px;
  padding-right: 1px;
  padding-left: 1px;
  display: table-cell;
  vertical-align: top;
}

.channelimg {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 0;
  center: 0;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

.channelgrid {
  margin-left: 10px;
  min-height: 60px;
}

.channelgrid .img-thumbnail {
  background-color: transparent;
  border: 0;
  max-width: 150%;
}

.channeltablecell {
  height: 135px;
}

/* make vid rows horizontal */

.chartRow {
  height: 700px;
}

.vidrow {
  max-width: 900px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
  border-color: transparent;
}

.vidthumbnail {
  width: 250px;
  padding-right: 20px;
  padding-left: 25px;
  display: table-cell;
  vertical-align: top;
}

.vidthumbnail2 {
  width: 290px;
  padding-right: 20px;
  padding-left: 25px;
  float: left;
  min-height: 350px;
  vertical-align: top;
}

.vidthumbnailcursor {
  cursor: pointer;
}

.img-default {
  width: 250px;
}

/* make contributors rows horizontal */

.contribthumbnail {
  width: 250px;
  padding-right: 20px;
  padding-left: 25px;
  display: table-cell;
  vertical-align: top;
}

.contribimg {
  display: block;
  margin: 0 15px;
  position: absolute;
  top: 0;
  center: 0;
  background-color: transparent;
  border: 0px;
}

.contribimg-default {
  width: 250px;
}

/* Make YouTube video responsive */
.vidcontainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.vidvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imgTable {
  width: default;
}

/* Featured Vid */
.imgFeatured {
  max-width: 100%;
  margin: auto;
  -webkit-filter: brightness(80%); /* Safari 6.0 - 9.0 */
  filter: brightness(80%);
}

.imgFeatured img {
  width: 100%;
  margin-top: -300px;
}

.imgFeatured::before {
  display: block;
  position: relative;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  /* margin-top: 150px; */
  height: 300px;
  width: 100%;
  content: "";
}

.imgFeatured::after {
  display: block;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  margin-top: -300px;
  height: 300px;
  width: 100%;
  content: "";
}

.txtFeatured {
  margin-top: -35%;
  margin-bottom: 25%;
  margin-left: auto;
  margin-right: auto;
  border-color: transparent;
  z-index: 1000;
}

.gridfeatured {
  margin-left: -30px;
  margin-right: -30px;
  padding: 0;
}

.cell-center {
  background-color: #ffffff;
  min-height: 550px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

/* Login Screen */

.login-logo {
  padding-top: 20px;
  display: block;
  margin: 0 auto;
}

.login-text {
  color: #222222;
  padding: 10px 0;
  margin: 0;
}

.login-button {
  margin: 10px 0;
  background-color: #e42a25;
  color: #ffffff;
  float: right;
}

.profile-radiobuttons {
  color: #222222;
  padding: 0;
  margin: 0 0 0 30px;
}

/*Channel Detail*/
.channel-icon {
  width: 250px;
  padding: 30px;
  align: right;
}

.linkto {
  color: #e42a25;
  float: left;
  padding: 0 0 10px 22px;
  margin: 0;
  display: block;
}

.linkcontrib {
  color: #e42a25;
  float: left;
  padding: 20px 0 10px 22px;
  margin: 0;
  display: block;
}

btn-link:focus,
.btn-link:hover {
  color: #e42a25;
  text-decoration: none;
}

.linkto2 {
  color: #e42a25;
  padding-bottom: 50px;
  padding-left: 0;
}

.text-red {
  color: #e42a25;
}

.text-white {
  color: #ffffff;
}

.contrib {
  padding-left: 25px;
  color: #ffffff;
  text-transform: uppercase;
}

.contrib2 {
  float: left;
  padding: 0;
  color: #ffffff;
  text-transform: uppercase;
}

.contrib2:before {
  content: "\a\a";
  white-space: pre;
}

.chartTitle {
  text-align: center;
  margin-top: -300px;
  font-family: arial, helvetica;
  font-size: 32px;
  font-weight: 300;
  color: #999999;
}

.ddChart {
  font-size: 14px;
  border: none;
  outline: none;
  color: red;
  padding: 12px 14px;
  background-color: #222222;
  font-family: inherit;
  margin: 0;
}

a.nav2menu,
a.nav2menu:hover,
a.nav2menu:link {
  color: #ffffff;
  text-decoration: none;
  padding: 0 15px;
}

.nav2 {
  background-color: #222222;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

